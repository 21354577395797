import { useState } from "react"
import elearning from "../sounds/e-learning_demo.wav"
import radioAd from "../sounds/radio_ad_demo.mp3"
import broadcast from "../sounds/broadcast_radio_segment_demo.wav"
import { DemoItem } from "../components"

const Demo = () => {
    const [clickedBroadcastTab, setClickedBroadcastTab] = useState(false)

    const handleClick = () => setClickedBroadcastTab(prev => !prev)

  return (
    <div className="flex flex-col items-center">
      <h1 className="text-white text-center text-3xl mb-5">Demo Recordings</h1>
      <DemoItem
      source={elearning}
      controls
      alt="Brian's elearning demo"
      title="E-Learning"
    />
      {clickedBroadcastTab ? (
        <div className="text-white p-3 rounded-full bg-red-500" onClick={handleClick}>Click for Broadcast Radio Demos</div>
        ) : (
          <div className="p-3 rounded-full text-white bg-blue-500" onClick={handleClick}>Click for Ad Demos</div>
        ) }
      <div className="mb-10 mt-5">
        {clickedBroadcastTab ? (
        <DemoItem
          source={radioAd}
          alt="Brian's radio ad demo"
          title="Radio Ad"
        />
      ) : (
        <DemoItem
          source={broadcast}
          alt="Brian's broadcast radio segment demo"
          title="Broadcast Radio"
        />
      )}
      </div>
    </div>
  );
};

export default Demo;
