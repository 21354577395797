import { Navbar, Footer, Main } from "./components";
function App() {
  return (
    <div className="min-h-screen">
      <div className="gradient-bg-welcome">
        <Navbar />
        <Main />
      </div>
      <Footer />
    </div>
  );
}

export default App;
