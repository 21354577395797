import logo from "../images/logo.jpg";
import venmo from "../images/Venmo_Logo_White.png"

const Footer = () => {
  return (
    <div className="w-full flex md:justify-center justify-between items-center flex-col p-4 gradient-bg-footer">
      <div className="w-full flex sm:flex-row flex-col justify-between items-center my-4">
        <div className="flex flex-0.5 justify-center items-center">
          <img
            src={logo}
            alt="Microphone Logo"
            className="w-32 rounded-full"
            data-cy="FLogo"
          />
        </div>
        <div className="flex flex-1 justify-evenly items-center flex-wrap sm:mt-0 mt-5 w-full">
          <a href="https://paypal.me/BrianMcCommon" target="_blank" rel="noreferrer noopener">
            <img src="https://www.paypalobjects.com/webstatic/mktg/logo/pp_cc_mark_37x23.jpg" />

          </a>
          <a href="https://venmo.com/code?user_id=3407641313280000441&created=1664931503" target="_blank" rel="noreferrer noopener">
            <img src={venmo} className="mx-2 cursor-pointer w-20 text-blue-500" />
          </a>
        </div>
      </div>
      <div className="flex justify-center items-center flex-col mt-5">
        <p className="text-white text-small text-center">
          Providing Vocal Talent for 20 years
        </p>
        <p className="text-white text-small text-center">
          Powerful, Commanding Voiceovers
        </p>
      </div>
      <div className="sm:w-[90%] w-full h-[0.25px] bg-gray-400 mt-5" />
      <div className="sm:w-[90%] w-full flex justify-between items-center mt-3">
        <p className="text-white text-small text-center">
          BKM Creative, LLC 2022
        </p>
        <p className="text-white text-small text-center">All Rights Reserved</p>
      </div>
    </div>
  );
};

export default Footer;
