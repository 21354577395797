import ReactAudioPlayer from "react-audio-player";

const DemoItem = ({ source, alt, title }) => {
  return (
    <div className="mb-10">
      <h2 className="text-white text-xl ml-5">{title}</h2>
      <ReactAudioPlayer alt={alt} controls src={source} />
    </div>
  );
};

export default DemoItem;
