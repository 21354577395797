import { Demo, ContactForm } from "../components";

import ReactPlayer from "react-player/youtube"

import standing from "../images/brian_standing.jpg"
import uniform from "../images/brian_speaking_in_uniform.jpg"
import concert from "../images/brian_speaking_at_concert_closeup.jpg"
import ceremony from "../images/brian_speaking_at_ceremony.jpg"
import booth from "../images/booth.jpg"
import demoVideo from "../videos/demo-video.mp4"
import logo from "../images/logo.jpg"


const Main = () => {
  return (
    <div>
      <div className="flex flex-col content-center mb-10">
        <h1 className="text-white text-center text-4xl mb-5">Brian McCommon</h1>
        <div>
          <p className="text-white text-center text-3xl mb-2">
            Voiceover Artist
          </p>
          <p className="text-white text-center text-3xl mb-5">
            Live Event Master of Ceremonies
          </p>
        </div>
        <img
          src={standing}
          className="w-40 opacity-95 self-center rounded-lg mb-10"
          alt="Brian_Standing"
        />
        <div className="self-center mb-5">
          <ReactPlayer url="https://youtu.be/FtoIalF5Gi0" width="350" height="600"/>
        </div>
        <p className="text-white w-3/5 self-center">
          Brian McCommon is a "mature male" voice over artist & live event emcee. Over a span of more than 20 years, he's announced for concerts, served as an emcee for military ceremonies, contributed a weekly segment for WMOV radio in the Mid Ohio Valley and appeared on a freelance basis at events across the eastern US.
        </p>
        <br />
        <p className="text-white w-3/5 self-center">
        Brian's been recognized for having a voice with a deep, rich & warm quality and with an ability to "tell a story". He's based in the Morgantown, WV region but can serve organizations anywhere, as a voice talent for radio, tv, social media, e-learning or even to narrate audiobooks.
      </p>
      <br />
      <p className="text-white w-3/5 text-left self-center">
      Brian is available to not only record voice overs for corporations, small businesses, civic & arts organizations and political campaigns but he also provides the fully produced versions with Royalty Free music tracks and sound effects. Another service in select cases, is he can assist with writing script for clients.
      </p>
      <br />
      <p className="text-white mr-10 ml-10 text-center text-lg">
      Inquiries for rates can be sent below and are based on the details of your project.
      </p>
      <br />
      <p className="text-white mr-10 ml-10 text-center text-2xl">
      Click the audio files below for sample ads as well as radio segments which previously aired.
      </p>
      <div className="sm:w-[90%] w-full h-[0.25px] bg-gray-400 self-center ml-10 mr-5 mt-5 mb-5" />
      </div>
      <Demo />
      <div className="flex flex-wrap justify-around justify-center">
        <img
          src={booth}
          className="lg:w-40 sm:w-20 rounded-full"
          alt="Brian_Closeup_in_Radio_Booth"
        />
        <img
          src={ceremony}
          className="lg:h-40 sm:h-20 rounded-full"
          alt="Brian_Speaking_at_Ceremony"
        />
        <img
          src={concert}
          className="lg:h-40 sm:h-20 rounded-full"
          alt="Brian Speaking at Concert"
        />
        <img
          src={uniform}
          className="lg:w-40 sm:w-20 rounded-full"
          alt="Brian Speaking in Uniform"
        />
      </div>
      <ContactForm />
    </div>
  );
};

export default Main;
