import { useState } from "react";
import emailjs from "@emailjs/browser"

const Input = ({ placeholder, name, type, value, handleChange }) => (
  <input
    placeholder={placeholder}
    name={name}
    type={type}
    value={value}
    onChange={e => handleChange(e, name)}
    className={
      "my-2 w-full rounded-sm p-2 outline-none bg-transparent text-white border-none text-sm white-glassmorphism"
    }
  />
);

const ContactForm = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: ""
  });

  const [emailSent, setEmailSent] = useState(false);

  const body = `${formData.firstName}
                ${formData.lastName}
                ${formData.email}
                ${formData.phone}
                ${formData.message}`;

  const handleChange = (e, name) => {
    setFormData(prevState => ({ ...prevState, [name]: e.target.value }));
  };

  const handleSubmit = e => {
    if (!formData.firstName || !formData.lastName || !formData.email) return alert("Please fill in your name and email.");

    const templateParams = formData

    emailjs.send('service_bvr4j0b', 'template_ngyx7xp', templateParams, 'P7xgvtxO3bFFDCapL')
    .then(function(response) {
       console.log('SUCCESS!', response.status, response.text);
    }, function(error) {
       console.log('FAILED...', error);
    });

    setFormData({
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      message: ""
    });

    setEmailSent(true)
  };

  return (
    <div className="flex flex-col items-center mt-10">
      <h3 className="text-white text-3xl text-center my-2">Contact Brian</h3>
      {emailSent ? (
        <div className="p-5 pt-3 mt-3 sm:w-96 w-full flex flex-col justify-start blue-glassmorphism">
          <h1 className="text-yellow-500 text-center text-xl">Thanks for getting in touch!</h1>
        </div>

      ) : (
        <div className="p-5 pt-3 mt-3 sm:w-96 w-full flex flex-col justify-start items-start blue-glassmorphism">
          <Input
            placeholder="First Name"
            name="firstName"
            value={formData.firstName}
            type="text"
            handleChange={handleChange}
          />
          <Input
            placeholder="Last Name"
            name="lastName"
            value={formData.lastName}
            type="text"
            handleChange={handleChange}
          />
          <Input
            placeholder="Email"
            name="email"
            value={formData.email}
            type="text"
            handleChange={handleChange}
          />
          <Input
            placeholder="Phone (optional)"
            name="phone"
            value={formData.phone}
            type="text"
            handleChange={handleChange}
          />
          <Input
            placeholder="Message (optional)"
            name="message"
            value={formData.message}
            type="text"
            handleChange={handleChange}
          />
          <div className="h-[1px] w-full bg-gray-400 my-2" />

          <button
            type="button"
            onClick={handleSubmit}
            className="text-white w-full mt-2 border-[1px] p-2 border-[#3d4f7c] rounded-full cursor-pointer transition delay-50 ease-in-out hover:-translate-y-1 hover:scale-105 hover:bg-yellow-500 text-center"
          >
            Submit Request
          </button>
        </div>
      )}
    </div>
  );
};

export default ContactForm;
